var showLen = 4,
    productInfo = '';

var selectItem = (name) => {
    return document.querySelector(name);
};

var selectChildItem = (parent, name) => {
    return parent.querySelector(name);
};

var selectItems = (names) => {
    return document.querySelectorAll(names);
};

document.addEventListener("DOMContentLoaded", function () {
    const popContainer = selectItem('.popup__wrapper'),
        popModal = selectItem('.popup__modal'),
        popVideoContainer = selectItem('.popup__modal_video'),
        popCert = selectItem('.popup__modal_cert'),
        popClose = selectItem('.popup__modal_exit');

    let popupCert = [popContainer, popModal, popCert],
        popupVideo = [popContainer, popModal, popVideoContainer];


    var removePopClass = (classItems, className) => {
        [...classItems].forEach(item => {
            item.classList.remove(className);
        });
    };

    var closePopup = () => {
        let active = selectItems('.popup_active'),
            hide = selectItems('.popup_hide'),
            video = selectItems('.popup_active_video');

        removePopClass(active, 'popup_active');
        removePopClass(hide, 'popup_hide');
        removePopClass(video, 'popup_active_video');
    };


    var closeModal = () => {
        let video = selectChildItem(popClose.parentElement, '.popup__modal_video_media');

        video.setAttribute('src', '');

        productInfo = '';
        closePopup();
    };

    popClose.onclick = () => {
        closeModal();
    };

    popContainer.onclick = () => {
        closeModal();
    };

    popModal.onclick = () => {
        event.stopPropagation()
    };

    const certificatesLink = selectItems('.certificates__link');

    [...certificatesLink].forEach(cert => {
        let dataCert = cert.getAttribute('data-cert');
        cert.onclick = () => {
            let certLink = selectItem('.popup__modal_cert_link');
            certLink.setAttribute('src', dataCert);

            [...popupCert].forEach(pop => {
                pop.classList.add('popup_active');
            });
        }
    });


    const playBtns = selectItems('.video__play');
    [...playBtns].forEach(btn => {
        btn.onclick = () => {
            let newVideo = btn.getAttribute('data-video-src');
            let modalVideo = selectItem('.popup__modal_video_media');

            modalVideo.setAttribute('src', newVideo + '?controls=3&rel=0&showinfo=0');

            [...popupVideo].forEach(pop => {
                pop.classList.add('popup_active');
            });

            popModal.classList.add('popup_active_video');
        };
    });
});


$('.prices__size_item').each(function(){
    $(this).attr('data-price-num', $(this).index()+1);
});

$('.prices__arrow').click(function(){
    let price =[1,25,50],
        activePrice = parseInt($('.prices__size_item_active').attr('data-price-num'));
    
        
    $('.prices__table').removeClass('prices__table_'+price[activePrice-1]);

    if($(this).hasClass('prices__arrow_right')){
        activePrice+= activePrice<3 ? 1: -2
    }else{
        activePrice-= activePrice>1 ? 1: -2
    }

    $('.prices__size_item_active').removeClass('prices__size_item_active');
    $('[data-price-num="'+activePrice+'"]').addClass('prices__size_item_active');

    $('.prices__table').addClass('prices__table_'+price[activePrice-1]);
});


function setProductsRating(){
    $('.products__rating').each(function () {
        let stars = parseInt($(this).attr('data-prod-rating'));
        for (let i = 0; i < stars; i++) {
            $($(this).find('.rating_star')[i]).addClass('rating_star_active');
        }
    })
}

function hideSomeProducts(){
    for(let i =0;i< $('.products__item').length;i++){
        let display = i<showLen*2 ? 'flex':'none';
        $($('.products__item')[i]).css('display',display);
    }
}


$('.js-tabs-control').click(function(){
    $('.js-tabs-control.active').removeClass('active');
    $(this).addClass('active');

    let tabIndex = $(this).attr('data-tab');
    showProductsByType(tabIndex)
});



function showProductsByType(type){
    if(type==='0'){
        $('.products__item').removeAttr('style');
        hideSomeProducts();
    }else{
        $('.products__item').each(function(){
            let display = $(this).attr('data-tab-num')===type ? 'flex' : 'none';
            $(this).css('display',display);
        })
    }
}


function showModalForm() {
    $('.popup__wrapper').addClass('popup_active');
    $('.popup__modal').addClass('popup_active');
    $('.popup__modal_form').addClass('popup_active');
}

    

$('[data-product-variant]').mouseenter(function(){
    let img = $(this).attr('data-img');
    $($(this).parents()[1]).find('.products__img-container').attr('style','background:url('+img+');');
});